// Colors
$brand-primary: #5b4681;
$grey: #dddddd;
$light-grey: #f9f9f9;
$dark-grey: #777777;

// Spacing
$space: .5rem;
$site-max-width: 110rem;

// Font sizes
$base-font-size: 1.4rem;
$large-font-size: 1.8rem;

// Form inputs
$input-border: $grey;
$input-spacing: 1rem;

// Boxed view
$boxed-view-overlay-bg: $grey;
$boxed-view-bg: white;

// Button
$button-bg: $brand-primary;
$button-color: white;
$button-pill-color: $brand-primary;
$button-secondary-bg: $light-grey;
$button-secondary-color: $dark-grey;
$button-secondary-border-color: $grey;

// Header
$header-bg: $brand-primary;
$header-color: white;

// item
$item-bg: $light-grey;
$item-border: darken($item-bg, 8%);
$item-text-color: $dark-grey;
