.item {
  background: $item-bg;
  border: 1px solid $item-border;
  margin-bottom: $space;
  padding: $space;
}

.item__message {
  color: $item-text-color;
}

.item__status-message {
  color: $item-text-color;
  font-style: italic;
  text-align: center;
  margin: 0;
}
