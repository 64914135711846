html {
  height: 100%;
  display: flex;
  body,
  #root {
    display: flex;
    height: 100%;
    width: 100%;
  }
}

/* player --------------------------------------------------------------- */

.loading-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #111;
  color: #fff;
}

.player-wrapper {
  height: 100%;
  background-color: #111;
  color: #fff;
}

.player-header {
  grid-area: playerheader;
}

.websiteurl-url {
  font-size: 2.5rem;
  font-weight: 700;
  margin-top: 0.2rem;
}

// .playerlogo {
//   grid-area: playerlogo;
// }
// .playerid {
//   grid-area: playerid;
// }
// .website {
//   grid-area: website;
// }
.playervideo {
  grid-area: playervideo;
}
.playernow {
  grid-area: playernow;
}
.playerqueue {
  grid-area: playerqueue;
}
.playerticker1 {
  grid-area: playerticker1;
}

.player-wrapper {
  display: grid;
  grid-gap: 0em;
  grid-template-areas:
    'playerheader'
    'playervideo'
    'playernow'
    'playerqueue'
    'playerticker1';
}

// .player-header {
//   display: grid;
//   grid-gap: 1em;
//   grid-template-columns: 33% 1fr 33%;
//   grid-template-rows: 100px;
//   grid-template-areas:
//     'playerlogo'
//     'playerid'
//     'website';
// }

.player-header {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background: #16325c; /* For browsers that do not support gradients */
  background: linear-gradient(to right, #16325e, #ef5c5a);
}

@media only screen and (min-width: 768px) {
  .player-wrapper {
    grid-gap: 0px;
    grid-template-columns: 25% 1fr 25%;
    grid-template-rows: 100px 1fr auto auto;
    grid-template-areas:
      'playerheader   playerheader   playerheader'
      'playervideo    playervideo    playervideo'
      'playerqueue    playerqueue    playerqueue'
      'playerticker1  playerticker1  playerticker1';
    max-width: 2800px;
    height: calc(100vh - 10px);
  }
}

.dark {
  background-color: #111;
  color: #fff;
}
.light {
  background-color: #fff;
  color: #000;
}

.website {
  display: flex;
  align-self: center;
  flex-direction: row;
}
.playerlogo {
  align-self: center;
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  flex: 1;
}

.playerid {
  text-align: center;
  align-self: center;
  justify-content: center;
  display: flex;
  flex: 1;
  flex-direction: row;
}
// img {
//   max-width: 100%;
// }
.playerid-code {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 4.5rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
  letter-spacing: 3px;
  color: #fff;
}
.playerid-name {
  font-size: 3rem;
  color: #fff;
}
.playerid-badge {
  color: #000;
  margin-left: 10px;
}
.website {
  font-size: 3rem;
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;
  margin-right: 20px;
}
.website-appdownload media-object {
  margin: 0 10px;
}
.website-url {
  padding-top: 5px;
}

.playerqueue {
  display: inline-flex;
  flex-direction: row;
  overflow: hidden;
  height: auto;
}

// .playerticker1 {
//   display: inline-flex;
//   flex-direction: row;
//   height: 80px;
// }

.playerqueue-count {
  display: flex;
  max-width: 90px;
  min-width: 90px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #111;
  flex: 1;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
}

.playerqueue-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
}

.playerqueue-nowplaying {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px 0 10px;
  width: 100%;
  background-color: #111;
}

.playerqueue-nowplaying-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0 10px 0;
  font-size: 1.6rem;
  width: 100%;
}

.playerqueue-nowplaying-divider {
  display: flex;
  margin-top: -10px;
  margin-bottom: 10px;
  height: 2px;
  width: 100%;
  background: #6d88d3; /* For browsers that do not support gradients */
  background: linear-gradient(to right, #9aaff7, #6d88d3);
}

.playerqueue-nowplaying-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
}
.playerqueue-nowplaying-username {
  flex-direction: row;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-family: 'Poppins';
  font-style: normal;
  font-size: 24px;
}

.playerqueue-nowplaying-image-container {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  margin-right: 10px;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    border-width: 0px;
    border-style: solid;
  }
}

.playlist-stats {
  margin-left: 10px;
}

.playlist-duration {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  font-size: 24px;
  white-space: nowrap;
  margin-left: 20px;
  margin-right: 10px;
}

.playerticker1 {
  background-color: #111;
  height: auto;
}

.playerticker-container {
  font-size: 26px;
  font-weight: 300;
  font-family: 'Poppins';
  letter-spacing: 0.15rem;
  color: #fff;
  line-height: 50px;
}

.playerticker-item {
  padding-right: 10px;
  vertical-align: middle;
}

.list-group-item {
  display: flex;
  flex-direction: row;
}

// .listgroup-item:first-child {
//   font-weight: bold;
// }

// .playerqueue-horz .listgroup-item:nth-child(n + 4) {
//   display: none;
// }

.listgroup-desc {
  font-weight: normal;
  color: #eee;
}

.gradient-bullet-separator {
  width: 9px;
  height: 9px;
  margin-right: 10px;
  margin-left: 10px;
  background: #9aaff7; /* For browsers that do not support gradients */
  background: linear-gradient(to top, #9aaff7, #6d88d3);
}

.collapse-action-icon {
}
.collapse-row-container .collapse-row-title {
  background-color: #e9ecef;
  margin-left: -10px;
  margin-right: -10px;
}

.action-button {
  background-color: #ac3f3d;
}

/* --------------------------------------------------------------- */
/* left over code not used

.video-list-description {
  font-size: 1.3rem;
  color:#333;
}*/
