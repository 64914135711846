@import '../sass/variables';

.public-header {
  margin-left: 15%;
  background-color: '#fff';
}
.public-page-content {
  background-image: url('../images/background1.jpg');
  background-size: 100%;
  background-repeat: no-repeat;
  height: 800px;
  padding-top: 100px;
}
.public-page-formwrapper {
  margin-left: 30%;
  margin-right: 30%;
  padding: 20px;
  border: 2px solid #f15b5a;
  border-radius: 8px;
  background-color: #eee;
}
.public-page-formaccess {
  font-weight: bold;
}
.public-page-form {
  display: flex;
  flex-direction: column;
  padding: 15px 10px;
}

.signin-link-wrapper {
  margin: 15px 0;
  text-align: center;
}
.signin-link {
  font-size: 1.5em;
}

.settings-nav {
  margin: 0;
  padding-left: 10px;
  padding-left: 10px;
  font-size: 1.5rem;
}
.settings-nav button,
.settings-nav li {
  border: 1px #bbb solid;
  border-radius: 8px !important;
  background-color: #fff;
  color: #212529;
  cursor: pointer;
  font-size: $base-font-size;
  line-height: 1.2;
  padding: $input-spacing;
  text-transform: capitalize;
  flex: 1;
}
