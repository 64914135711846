.boxed-view {
  align-items: center;
  background: $boxed-view-overlay-bg;
  display: flex;
  justify-content: center;
  height: 60vh;
  width: 100vw;
}

.boxed-view--modal {
  background: fade-out($boxed-view-overlay-bg, .3);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.boxed-view__box {
  background-color: $boxed-view-bg;
  padding: 2.4rem;
  text-align: center;
  width: 24rem;
}

.boxed-view__form {
  display: flex;
  flex-direction: column;
}
