.playerqueue-vert {
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
}
.playerqueue-vert .listgroup-item {
  width: 80%;
  margin-bottom: 10px;
}

.playerqueue-vert .listgroup-desc {
  font-weight: normal;
  color: #222;
}

.playerqueue-horz {
  display: flex;
  font-size: 1.5rem;
}

.playerqueue-horz .listgroup-item:nth-child(-n + 3) {
  font-size: 1.5rem;
  width: 300px;
  .listgroup-info-wrapper {
    display: flex;
    flex-direction: column;
  }

  .listgroup-user-collapsed {
    display: none;
  }
}

.playerqueue-horz .listgroup-item {
  width: 150px;
  margin-right: 10px;
  padding-right: 10px;
  flex-direction: row;
  display: flex;
  .listgroup-image-container {
    width: 30px;
    height: 30px;
    margin-right: 8px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 15px;
      border-width: 0px;
      border-style: solid;
    }
  }

  .listgroup-user-collapsed {
    margin-top: 18px;
    .listgroup-user-collapsed-image-container {
      width: 40px;
      height: 40px;
      margin-right: 8px;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        border-width: 0px;
        border-style: solid;
      }
    }
  }
}
