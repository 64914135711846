@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
.public-header {
  margin-left: 15%;
  background-color: '#fff'; }

.public-page-content {
  background-image: url(/static/media/background1.029c1b98.jpg);
  background-size: 100%;
  background-repeat: no-repeat;
  height: 800px;
  padding-top: 100px; }

.public-page-formwrapper {
  margin-left: 30%;
  margin-right: 30%;
  padding: 20px;
  border: 2px solid #f15b5a;
  border-radius: 8px;
  background-color: #eee; }

.public-page-formaccess {
  font-weight: bold; }

.public-page-form {
  display: flex;
  flex-direction: column;
  padding: 15px 10px; }

.signin-link-wrapper {
  margin: 15px 0;
  text-align: center; }

.signin-link {
  font-size: 1.5em; }

.settings-nav {
  margin: 0;
  padding-left: 10px;
  padding-left: 10px;
  font-size: 1.5rem; }

.settings-nav button,
.settings-nav li {
  border: 1px #bbb solid;
  border-radius: 8px !important;
  background-color: #fff;
  color: #212529;
  cursor: pointer;
  font-size: 1.4rem;
  line-height: 1.2;
  padding: 1rem;
  text-transform: capitalize;
  flex: 1 1; }




.modal-title {
  white-space: pre-wrap; }






.public-header {
  margin-left: 15%;
  background-color: '#fff'; }

.public-page-content {
  background-image: url(/static/media/background1.029c1b98.jpg);
  background-size: 100%;
  background-repeat: no-repeat;
  height: 800px;
  padding-top: 100px; }

.public-page-formwrapper {
  margin-left: 30%;
  margin-right: 30%;
  padding: 20px;
  border: 2px solid #f15b5a;
  border-radius: 8px;
  background-color: #eee; }

.public-page-formaccess {
  font-weight: bold; }

.public-page-form {
  display: flex;
  flex-direction: column;
  padding: 15px 10px; }

.signin-link-wrapper {
  margin: 15px 0;
  text-align: center; }

.signin-link {
  font-size: 1.5em; }

.settings-nav {
  margin: 0;
  padding-left: 10px;
  padding-left: 10px;
  font-size: 1.5rem; }

.settings-nav button,
.settings-nav li {
  border: 1px #bbb solid;
  border-radius: 8px !important;
  background-color: #fff;
  color: #212529;
  cursor: pointer;
  font-size: 1.4rem;
  line-height: 1.2;
  padding: 1rem;
  text-transform: capitalize;
  flex: 1 1; }

.listgroup-info-wrapper {
  padding-right: 5px;
  display: none; }

.listgroup-thumbnail img {
  float: left; }

.playlist-username {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px; }

.playerqueue-vert {
  display: flex;
  flex-direction: column;
  font-size: 1.5rem; }

.playerqueue-vert .listgroup-item {
  width: 80%;
  margin-bottom: 10px; }

.playerqueue-vert .listgroup-desc {
  font-weight: normal;
  color: #222; }

.playerqueue-horz {
  display: flex;
  font-size: 1.5rem; }

.playerqueue-horz .listgroup-item:nth-child(-n + 3) {
  font-size: 1.5rem;
  width: 300px; }
  .playerqueue-horz .listgroup-item:nth-child(-n + 3) .listgroup-info-wrapper {
    display: flex;
    flex-direction: column; }
  .playerqueue-horz .listgroup-item:nth-child(-n + 3) .listgroup-user-collapsed {
    display: none; }

.playerqueue-horz .listgroup-item {
  width: 150px;
  margin-right: 10px;
  padding-right: 10px;
  flex-direction: row;
  display: flex; }
  .playerqueue-horz .listgroup-item .listgroup-image-container {
    width: 30px;
    height: 30px;
    margin-right: 8px; }
    .playerqueue-horz .listgroup-item .listgroup-image-container img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 15px;
      border-width: 0px;
      border-style: solid; }
  .playerqueue-horz .listgroup-item .listgroup-user-collapsed {
    margin-top: 18px; }
    .playerqueue-horz .listgroup-item .listgroup-user-collapsed .listgroup-user-collapsed-image-container {
      width: 40px;
      height: 40px;
      margin-right: 8px; }
      .playerqueue-horz .listgroup-item .listgroup-user-collapsed .listgroup-user-collapsed-image-container img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        border-width: 0px;
        border-style: solid; }

html {
  height: 100%;
  display: flex; }
  html body,
  html #root {
    display: flex;
    height: 100%;
    width: 100%; }

/* player --------------------------------------------------------------- */
.loading-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #111;
  color: #fff; }

.player-wrapper {
  height: 100%;
  background-color: #111;
  color: #fff; }

.player-header {
  grid-area: playerheader; }

.websiteurl-url {
  font-size: 2.5rem;
  font-weight: 700;
  margin-top: 0.2rem; }

.playervideo {
  grid-area: playervideo; }

.playernow {
  grid-area: playernow; }

.playerqueue {
  grid-area: playerqueue; }

.playerticker1 {
  grid-area: playerticker1; }

.player-wrapper {
  display: grid;
  grid-gap: 0em;
  grid-template-areas: 'playerheader' 'playervideo' 'playernow' 'playerqueue' 'playerticker1'; }

.player-header {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background: #16325c;
  /* For browsers that do not support gradients */
  background: -webkit-gradient(linear, left top, right top, from(#16325e), to(#ef5c5a));
  background: -webkit-linear-gradient(left, #16325e, #ef5c5a);
  background: linear-gradient(to right, #16325e, #ef5c5a); }

@media only screen and (min-width: 768px) {
  .player-wrapper {
    grid-gap: 0px;
    grid-template-columns: 25% 1fr 25%;
    grid-template-rows: 100px 1fr auto auto;
    grid-template-areas: 'playerheader   playerheader   playerheader' 'playervideo    playervideo    playervideo' 'playerqueue    playerqueue    playerqueue' 'playerticker1  playerticker1  playerticker1';
    max-width: 2800px;
    height: calc(100vh - 10px); } }

.dark {
  background-color: #111;
  color: #fff; }

.light {
  background-color: #fff;
  color: #000; }

.website {
  display: flex;
  align-self: center;
  flex-direction: row; }

.playerlogo {
  align-self: center;
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  flex: 1 1; }

.playerid {
  text-align: center;
  align-self: center;
  justify-content: center;
  display: flex;
  flex: 1 1;
  flex-direction: row; }

.playerid-code {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 4.5rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
  letter-spacing: 3px;
  color: #fff; }

.playerid-name {
  font-size: 3rem;
  color: #fff; }

.playerid-badge {
  color: #000;
  margin-left: 10px; }

.website {
  font-size: 3rem;
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1 1;
  margin-right: 20px; }

.website-appdownload media-object {
  margin: 0 10px; }

.website-url {
  padding-top: 5px; }

.playerqueue {
  display: inline-flex;
  flex-direction: row;
  overflow: hidden;
  height: auto; }

.playerqueue-count {
  display: flex;
  max-width: 90px;
  min-width: 90px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #111;
  flex: 1 1;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400; }

.playerqueue-container {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400; }

.playerqueue-nowplaying {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px 0 10px;
  width: 100%;
  background-color: #111; }

.playerqueue-nowplaying-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0 10px 0;
  font-size: 1.6rem;
  width: 100%; }

.playerqueue-nowplaying-divider {
  display: flex;
  margin-top: -10px;
  margin-bottom: 10px;
  height: 2px;
  width: 100%;
  background: #6d88d3;
  /* For browsers that do not support gradients */
  background: -webkit-gradient(linear, left top, right top, from(#9aaff7), to(#6d88d3));
  background: -webkit-linear-gradient(left, #9aaff7, #6d88d3);
  background: linear-gradient(to right, #9aaff7, #6d88d3); }

.playerqueue-nowplaying-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 26px; }

.playerqueue-nowplaying-username {
  flex-direction: row;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-family: 'Poppins';
  font-style: normal;
  font-size: 24px; }

.playerqueue-nowplaying-image-container {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  margin-right: 10px; }
  .playerqueue-nowplaying-image-container img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    border-width: 0px;
    border-style: solid; }

.playlist-stats {
  margin-left: 10px; }

.playlist-duration {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  font-size: 24px;
  white-space: nowrap;
  margin-left: 20px;
  margin-right: 10px; }

.playerticker1 {
  background-color: #111;
  height: auto; }

.playerticker-container {
  font-size: 26px;
  font-weight: 300;
  font-family: 'Poppins';
  letter-spacing: 0.15rem;
  color: #fff;
  line-height: 50px; }

.playerticker-item {
  padding-right: 10px;
  vertical-align: middle; }

.list-group-item {
  display: flex;
  flex-direction: row; }

.listgroup-desc {
  font-weight: normal;
  color: #eee; }

.gradient-bullet-separator {
  width: 9px;
  height: 9px;
  margin-right: 10px;
  margin-left: 10px;
  background: #9aaff7;
  /* For browsers that do not support gradients */
  background: -webkit-gradient(linear, left bottom, left top, from(#9aaff7), to(#6d88d3));
  background: -webkit-linear-gradient(bottom, #9aaff7, #6d88d3);
  background: linear-gradient(to top, #9aaff7, #6d88d3); }

.collapse-row-container .collapse-row-title {
  background-color: #e9ecef;
  margin-left: -10px;
  margin-right: -10px; }

.action-button {
  background-color: #ac3f3d; }

/* --------------------------------------------------------------- */
/* left over code not used

.video-list-description {
  font-size: 1.3rem;
  color:#333;
}*/

.App {
  text-align: center; }

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

/*
* {
  margin: 0;
  padding: 0;
}
*/
html {
  font-size: 62.5%;
  background: #000; }

body {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 1.4rem;
  margin: 0; }

h1 {
  font-weight: 300;
  margin-bottom: 0.5rem; }

h2 {
  font-size: 1.8rem;
  margin-bottom: 0.5rem; }

p {
  margin-bottom: 0.5rem;
  color: black; }

a {
  color: black; }

input[type='text'],
input[type='email'],
input[type='password'] {
  border: 1px solid #dddddd;
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  padding: 1rem; }

.boxed-view {
  align-items: center;
  background: #dddddd;
  display: flex;
  justify-content: center;
  height: 60vh;
  width: 100vw; }

.boxed-view--modal {
  background: rgba(221, 221, 221, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.boxed-view__box {
  background-color: white;
  padding: 2.4rem;
  text-align: center;
  width: 24rem; }

.boxed-view__form {
  display: flex;
  flex-direction: column; }

.button {
  background-color: #5b4681;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 1.4rem;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  padding: 1rem;
  text-transform: uppercase; }

.button--link {
  display: inline-block;
  text-decoration: none; }

.button--pill {
  background-color: transparent;
  border: 1px solid #5b4681;
  color: #5b4681;
  margin: 0 0.25rem 0 0;
  padding: 0.3rem 0.8rem; }

.button--secondary {
  background: #f9f9f9;
  border: 1px solid #dddddd;
  color: #777777; }

.button--link-text {
  background: none;
  margin: 0;
  padding: 0;
  text-decoration: underline;
  text-transform: none; }

.header {
  background: #5b4681;
  color: white; }

.header__content {
  display: flex;
  justify-content: space-between;
  max-width: 110rem;
  margin: 0 auto;
  padding: 0.5rem; }

.header__title {
  margin: 0; }

.checkbox {
  display: block;
  margin-bottom: 0.5rem; }

.checkbox__box {
  margin-right: 0.25rem; }

.item {
  background: #f9f9f9;
  border: 1px solid #e5e5e5;
  margin-bottom: 0.5rem;
  padding: 0.5rem; }

.item__message {
  color: #777777; }

.item__status-message {
  color: #777777;
  font-style: italic;
  text-align: center;
  margin: 0; }

.logo,
.status {
  height: 90px; }

.footer p {
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 0;
  text-align: center;
  font-size: 2rem; }

/* --------------------------------------------------------------- */
.header-wrapper {
  background-color: #16325c; }

.nav-item {
  list-style: none;
  text-align: center; }

.nav-link .fa {
  color: #fff; }

.page-title {
  font-size: 2rem;
  padding-left: 10px;
  padding-top: 50px; }

.notification-wrapper {
  padding-top: 120px; }

.searchcontainer,
.homequeuecontainer,
.favoritecontainer {
  margin: 10px 0 60px 0 !important;
  display: flex;
  flex-direction: column; }

.settings-nav {
  margin: 0;
  padding-left: 10px;
  padding-left: 10px;
  font-size: 1.5rem; }

.settings-nav button,
.settings-nav li {
  margin: 0.5rem;
  border: 1px #bbb solid;
  border-radius: 8px !important; }

.playlist {
  /* overflow-y: scroll; */
  height: 850px;
  background-color: #111; }

.player-connect {
  padding: 0 5px; }

.player-connect-msg {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0.5rem; }

.player-connect-id {
  font-size: 1.8rem;
  padding-left: 10px; }

.footer-wrapper {
  background-color: #fff;
  font-size: 1.4rem;
  border-top: 1px solid #16325c; }

.footer-wrapper .nav-link .fa {
  color: #ccc; }

.footer-wrapper .nav-link .fa-active {
  color: #f15b5b; }

.btn {
  font-size: 1.5rem !important; }

.media img {
  height: 75px;
  width: 100px; }

.modal-player-title .nav-link {
  display: inline; }

.helpdocs p {
  font-size: 1.3rem; }

.playlist-header {
  margin: 0; }

.playlist-header-id {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  background-color: #ac3f3d;
  color: #fff;
  text-align: center;
  font-size: 4rem;
  padding-bottom: 5px;
  letter-spacing: 3px; }

.playlist-header-name {
  background-color: #000;
  color: #fff;
  text-align: left;
  font-size: 3rem;
  margin: 5px; }

.videoplayer {
  background-color: #000; }

.messages {
  /* margin: 5px 0 80px 0 !important; */
  background-color: #000;
  padding: 10px 0; }

.marquee3k {
  background-color: #222;
  padding: 0; }

.marquee3k__copy {
  box-sizing: border-box;
  font-size: 36px;
  font-weight: 400;
  letter-spacing: 0.15rem;
  color: #fff; }

.marquee-msg {
  padding-right: 50px; }

.beta {
  height: 50px;
  vertical-align: top; }

.player-tab,
.black-bg {
  background-color: #000; }

.player-stepforward,
.player-pause,
.player-mute {
  max-width: 50px !important; }

.player-stepforward {
  margin-right: 8px; }

.player-pause {
  margin-right: 8px; }

.player-volumedown {
  padding-right: 5px; }

.player-volumeup {
  padding-left: 5px; }

.player-mute {
  margin-left: 8px; }

.connect-help {
  padding: 10px;
  font-size: 1.5rem; }

.playerSettings-form,
.username-form {
  font-size: 1.5rem; }

.playerSettings-form input,
.username-form input {
  padding-left: 10px;
  margin-bottom: 0;
  vertical-align: middle; }

.playerSettings-form span,
.username-form span {
  margin-right: 10px; }

.playerSettings-form label,
.username-form label {
  margin-bottom: 10px; }

.playerSettings-form input[type='radio'],
input[type='checkbox'] {
  -webkit-transform: scale(1.5);
          transform: scale(1.5); }

.react-numeric-input input {
  padding: 8px 5px !important;
  width: 120px; }

.ripple {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0); }

.ripple:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: -webkit-radial-gradient(circle, #000 10%, transparent 10.01%);
  background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  -webkit-transform: scale(10, 10);
          transform: scale(10, 10);
  opacity: 0;
  -webkit-transition: opacity 1s, -webkit-transform 0.4s;
  transition: opacity 1s, -webkit-transform 0.4s;
  transition: transform 0.4s, opacity 1s;
  transition: transform 0.4s, opacity 1s, -webkit-transform 0.4s; }

.ripple:active:after {
  -webkit-transform: scale(0, 0);
          transform: scale(0, 0);
  opacity: 0.2;
  -webkit-transition: 0s;
  transition: 0s; }

@media (max-width: 1600px) {
  .logo {
    height: 90px; }
  .notification-wrapper {
    padding-top: 140px; } }

@media (max-width: 1200px) {
  .logo {
    height: 90px; }
  .notification-wrapper {
    padding-top: 130px; } }

@media (max-width: 768px) {
  .logo {
    height: 75px; }
  .notification-wrapper {
    padding-top: 100px; } }

@media (max-width: 480px) {
  .logo {
    height: 45px; }
  .notification-wrapper {
    padding-top: 60px; } }

/*** Styles added to fix the issue with zoom in on iphone ***/
/* iPhone < 5: */
@media screen and (device-aspect-ratio: 2 / 3) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px; } }

/* iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40 / 71) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px; } }

/* iPhone 6, iPhone 6s, iPhone 7 portrait/landscape */
@media screen and (device-aspect-ratio: 375 / 667) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px; } }

/* iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus portrait/landscape */
@media screen and (device-aspect-ratio: 9 / 16) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px; } }

/* ----------- iPhone 4 and 4S ----------- */
/* Portrait and Landscape */
/* Portrait */
/* Landscape */
/* ----------- iPhone 5 and 5S ----------- */
/* Portrait and Landscape */
/* Portrait */
/* Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .playlist-header-id {
    font-size: 2rem; }
  .playlist-header-name {
    font-size: 1.5rem; }
  .react-player {
    height: 20%; }
  .media-left-playlist img {
    display: none; } }

/* ----------- iPhone 6 ----------- */
/* Portrait and Landscape */
/* Portrait */
/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .playlist-header-id {
    font-size: 2rem; }
  .playlist-header-name {
    font-size: 1.5rem; }
  .react-player {
    height: 20%; }
  .media-left-playlist img {
    display: none; } }

/* ----------- iPhone 6+ ----------- */
/* Portrait and Landscape */
/* Portrait */
/* Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .playlist-header-id {
    font-size: 2rem; }
  .playlist-header-name {
    font-size: 1.5rem; }
  .react-player {
    height: 20%; }
  .media-left-playlist img {
    display: none; } }

