.header {
  background: $header-bg;
  color: $header-color;
}

.header__content {
  display: flex;
  justify-content: space-between;
  max-width: $site-max-width;
  margin: 0 auto;
  padding: $space;
}

.header__title {
  margin: 0;
}
