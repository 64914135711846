@import './sass/fonts';

.logo,
.status {
  height: 90px;
}

.footer p {
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 0;
  text-align: center;
  font-size: 2rem;
}

/* --------------------------------------------------------------- */

.header-wrapper {
  background-color: #16325c;
}
.nav-item {
  list-style: none;
  text-align: center;
}
.nav-link .fa {
  color: #fff;
}
.page-title {
  font-size: 2rem;
  padding-left: 10px;
  padding-top: 50px;
}
.notification-wrapper {
  padding-top: 120px;
}
.content-wrapper {
}
.searchcontainer,
.homequeuecontainer,
.favoritecontainer {
  margin: 10px 0 60px 0 !important;
  display: flex;
  flex-direction: column;
}

.settings-nav {
  margin: 0;
  padding-left: 10px;
  padding-left: 10px;
  font-size: 1.5rem;
}
.settings-nav button,
.settings-nav li {
  margin: 0.5rem;
  border: 1px #bbb solid;
  border-radius: 8px !important;
}

.playlist {
  /* overflow-y: scroll; */
  height: 850px;
  background-color: #111;
}
.player-connect {
  padding: 0 5px;
}
.player-connect-msg {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.player-connect-id {
  font-size: 1.8rem;
  padding-left: 10px;
}

.footer-wrapper {
  background-color: #fff;
  font-size: 1.4rem;
  border-top: 1px solid #16325c;
}
.footer-wrapper .nav-link .fa {
  color: #ccc;
}
.footer-wrapper .nav-link .fa-active {
  color: #f15b5b;
}

.btn {
  font-size: 1.5rem !important;
}
.media img {
  height: 75px;
  width: 100px;
}
.modal-player-title .nav-link {
  display: inline;
}

.helpdocs p {
  font-size: 1.3rem;
}

.playlist-header {
  margin: 0;
}
.playlist-header-id {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  background-color: #ac3f3d;
  color: #fff;
  text-align: center;
  font-size: 4rem;
  padding-bottom: 5px;
  letter-spacing: 3px;
}
.playlist-header-name {
  background-color: #000;
  color: #fff;
  text-align: left;
  font-size: 3rem;
  margin: 5px;
}
// li:nth-child(n + 4) .media-left-playlist,
// li:nth-child(n + 4) .playlist-stats {
//   display: none;
// }

.videoplayer {
  background-color: #000;
}
.messages {
  /* margin: 5px 0 80px 0 !important; */
  background-color: #000;
  padding: 10px 0;
}

.marquee3k {
  background-color: #222;
  padding: 0;
}
.marquee3k__copy {
  box-sizing: border-box;
  font-size: 36px;
  font-weight: 400;
  letter-spacing: 0.15rem;
  color: #fff;
}
.marquee-msg {
  padding-right: 50px;
}

.beta {
  height: 50px;
  vertical-align: top;
}

.player-tab,
.black-bg {
  background-color: #000;
}
.player-stepforward,
.player-pause,
.player-mute {
  max-width: 50px !important;
}
.player-stepforward {
  margin-right: 8px;
}
.player-pause {
  margin-right: 8px;
}
.player-volumedown {
  padding-right: 5px;
}
.player-volumeup {
  padding-left: 5px;
}
.player-mute {
  margin-left: 8px;
}
.searchquery {
}

.connect-help {
  padding: 10px;
  font-size: 1.5rem;
}
.playerSettings-form,
.username-form {
  font-size: 1.5rem;
}
.playerSettings-form input,
.username-form input {
  padding-left: 10px;
  margin-bottom: 0;
  vertical-align: middle;
}

.playerSettings-form span,
.username-form span {
  margin-right: 10px;
}

.playerSettings-form label,
.username-form label {
  margin-bottom: 10px;
}

.playerSettings-form input[type='radio'],
input[type='checkbox'] {
  transform: scale(1.5);
}

.react-numeric-input input {
  padding: 8px 5px !important;
  width: 120px;
}

.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}
.ripple:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.4s, opacity 1s;
}
.ripple:active:after {
  transform: scale(0, 0);
  opacity: 0.2;
  transition: 0s;
}

@media (max-width: 1600px) {
  .logo {
    height: 90px;
  }
  .notification-wrapper {
    padding-top: 140px;
  }
}
@media (max-width: 1200px) {
  .logo {
    height: 90px;
  }
  .notification-wrapper {
    padding-top: 130px;
  }
}
@media (max-width: 768px) {
  .logo {
    height: 75px;
  }
  .notification-wrapper {
    padding-top: 100px;
  }
}
@media (max-width: 480px) {
  .logo {
    height: 45px;
  }
  .notification-wrapper {
    padding-top: 60px;
  }
}

/*** Styles added to fix the issue with zoom in on iphone ***/
/* iPhone < 5: */
@media screen and (device-aspect-ratio: 2/3) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px;
  }
}

/* iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px;
  }
}

/* iPhone 6, iPhone 6s, iPhone 7 portrait/landscape */
@media screen and (device-aspect-ratio: 375/667) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px;
  }
}

/* iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus portrait/landscape */
@media screen and (device-aspect-ratio: 9/16) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px;
  }
}

/* ----------- iPhone 4 and 4S ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
}

/* ----------- iPhone 5 and 5S ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .playlist-header-id {
    font-size: 2rem;
  }
  .playlist-header-name {
    font-size: 1.5rem;
  }
  .react-player {
    height: 20%;
  }
  .media-left-playlist img {
    display: none;
  }
}

/* ----------- iPhone 6 ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .playlist-header-id {
    font-size: 2rem;
  }
  .playlist-header-name {
    font-size: 1.5rem;
  }
  .react-player {
    height: 20%;
  }
  .media-left-playlist img {
    display: none;
  }
}

/* ----------- iPhone 6+ ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
}

/* Portrait */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .playlist-header-id {
    font-size: 2rem;
  }
  .playlist-header-name {
    font-size: 1.5rem;
  }
  .react-player {
    height: 20%;
  }
  .media-left-playlist img {
    display: none;
  }
}
