.listgroup-info-wrapper {
  padding-right: 5px;
  display: none;
}

.listgroup-thumbnail img {
  float: left;
}

.playlist-username {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}
