.button {
  background-color: $button-bg;
  border: none;
  color: $button-color;
  cursor: pointer;
  font-size: $base-font-size;
  line-height: 1.2;
  margin-bottom: $space;
  padding: $input-spacing;
  text-transform: uppercase;
}

.button--link {
  display: inline-block;
  text-decoration: none;
}

.button--pill {
  background-color: transparent;
  border: 1px solid $button-pill-color;
  color: $button-pill-color;
  margin: 0 ($space / 2) 0 0;
  padding: 0.3rem 0.8rem;
}

.button--secondary {
  background: $button-secondary-bg;
  border: 1px solid $button-secondary-border-color;
  color: $button-secondary-color;
}

.button--link-text {
  background: none;
  margin: 0;
  padding: 0;
  text-decoration: underline;
  text-transform: none;
}
