/*
* {
  margin: 0;
  padding: 0;
}
*/
html {
  font-size: 62.5%;
  background: #000;
}

body {
  font-family: Helvetica, Arial, sans-serif;
  font-size: $base-font-size;
  margin: 0;
}

h1 {
  font-weight: 300;
  margin-bottom: $space;
}

h2 {
  font-size: $large-font-size;
  margin-bottom: $space;
}

p {
  margin-bottom: $space;
  color: black;
}

a {
  color: black;
}

input[type='text'],
input[type='email'],
input[type='password'] {
  border: 1px solid $input-border;
  font-size: $base-font-size;
  margin-bottom: $space;
  padding: $input-spacing;
}
